var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-11 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("updateTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-1 my-auto d-flex justify-end",
                      attrs: { "aria-label": "cross", icon: "", width: "50" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-8 grey--text text--darken-4" },
                [
                  _c("v-row", { staticClass: "text-h6" }, [
                    _vm._v(
                      " According to Utah Code 54-8a-4(2)(b) in the DAMAGE TO UNDERGROUND UTILITY FACILITIES ACT, Notice shall be given not more than 14 days before excavation begins. "
                    ),
                  ]),
                  _c("v-row", { staticClass: "text-h6" }, [
                    _vm._v(
                      " Additionally, according to Utah Code 54-8a-4(9), an excavator may be responsible for the costs incurred by an operator to remark its underground facilities following the second or subsequent notice given by an excavator at the same location. An excavator should not submit multiple notices for the same location before excavation activity has started or after excavation activity has been completed. "
                    ),
                  ]),
                  _c("v-row", { staticClass: "subtitle-1 mt-6" }, [
                    _vm._v(
                      " Will you be excavating within the 14-calendar day life of this update request? "
                    ),
                  ]),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.excavationCheck,
                        callback: function ($$v) {
                          _vm.excavationCheck = $$v
                        },
                        expression: "excavationCheck",
                      },
                    },
                    [
                      _c("v-radio", {
                        key: "Yes",
                        attrs: { label: "Yes", value: "Yes" },
                      }),
                      _c("v-radio", {
                        key: "No",
                        attrs: { label: "No", value: "No" },
                      }),
                    ],
                    1
                  ),
                  _c("v-row", { staticClass: "subtitle-1" }, [
                    _vm._v(
                      " Does the work type and area of the excavation remain the same, as provided on the original quest? "
                    ),
                  ]),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.workTypeCheck,
                        callback: function ($$v) {
                          _vm.workTypeCheck = $$v
                        },
                        expression: "workTypeCheck",
                      },
                    },
                    [
                      _c("v-radio", {
                        key: "Yes",
                        attrs: { label: "Yes", value: "Yes" },
                      }),
                      _c("v-radio", {
                        key: "No",
                        attrs: { label: "No", value: "No" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { outlined: "", color: "primary" },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                disabled:
                                  !_vm.excavationCheck || !_vm.workTypeCheck,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.alertDialog = true
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.alertDialog,
            callback: function ($$v) {
              _vm.alertDialog = $$v
            },
            expression: "alertDialog",
          },
        },
        [
          _vm.excavationCheck == "Yes" && _vm.workTypeCheck == "Yes"
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "h5 pl-3" }, [
                    _vm._v(" Update Ticket "),
                  ]),
                  _c("v-divider", { staticClass: "mb-6" }),
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", [
                        _vm._v(
                          " Please select from the dropdown below the reason you need to Update this ticket for excavation work that will take place in the 14-calendar day life of the locate request "
                        ),
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "mt-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.excavationList,
                              "menu-props": { bottom: true, offsetY: true },
                              outlined: "",
                              dense: "",
                              required: "",
                            },
                            model: {
                              value: _vm.selectedExcavation,
                              callback: function ($$v) {
                                _vm.selectedExcavation = $$v
                              },
                              expression: "selectedExcavation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [_c("v-divider", { staticClass: "mb-6" })],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { outlined: "", color: "primary" },
                              on: { click: _vm.closeAlertDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                disabled: !_vm.selectedExcavation,
                              },
                              on: { click: _vm.updateTicket },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.excavationCheck == "Yes" && _vm.workTypeCheck == "No"
            ? _c(
                "v-card",
                { staticClass: "text-none" },
                [
                  _c("v-card-title", { staticClass: "h5 pl-3" }, [
                    _vm._v(" Alert! "),
                  ]),
                  _c("v-divider", { staticClass: "mb-6" }),
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", [
                        _vm._v(
                          " New Locate Request must be submitted because the work type and/or excavation area has changed. "
                        ),
                      ]),
                      _c(
                        "v-row",
                        [_c("v-divider", { staticClass: "my-6" })],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { outlined: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.alertDialog = false
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                disabled: !_vm.submitButtonClicked,
                              },
                              on: { click: _vm.commentTicket },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("createANewLocateRequestNow")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.excavationCheck == "No" &&
          (_vm.workTypeCheck == "No" || _vm.workTypeCheck == "Yes")
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "h5 pl-3" }, [
                    _vm._v(" Alert! "),
                  ]),
                  _c("v-divider", { staticClass: "mb-6" }),
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", [
                        _vm._v(
                          " The Locate Request will not be Updated because no excavation is going to occur within the 14 day life of a Locate Request and/or the work type/area of excavation has changed. If needed, please submit a New Locate Request at least 2 workdays before but not more than 14 days prior to when excavation will take place. "
                        ),
                      ]),
                      _c(
                        "v-row",
                        [_c("v-divider", { staticClass: "my-6" })],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                disabled: !_vm.submitButtonClicked,
                              },
                              on: { click: _vm.commentTicket },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }