<template>
  <div>
    <v-dialog fullscreen persistent v-model="dialog">
      <v-card>
        <v-row class="ma-0 ml-4">
          <span
            class="col-11 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("updateTicket") }}</span
          >
          <v-btn
            class="col-1 my-auto d-flex justify-end"
            aria-label="cross"
            icon
            width="50"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-card-text class="pt-8 grey--text text--darken-4">
          <v-row class="text-h6">
            According to Utah Code 54-8a-4(2)(b) in the DAMAGE TO UNDERGROUND
            UTILITY FACILITIES ACT, Notice shall be given not more than 14 days
            before excavation begins.
          </v-row>
          <v-row class="text-h6">
            Additionally, according to Utah Code 54-8a-4(9), an excavator may be
            responsible for the costs incurred by an operator to remark its
            underground facilities following the second or subsequent notice
            given by an excavator at the same location. An excavator should not
            submit multiple notices for the same location before excavation
            activity has started or after excavation activity has been
            completed.
          </v-row>
          <v-row class="subtitle-1 mt-6">
            Will you be excavating within the 14-calendar day life of this
            update request?
          </v-row>
          <v-radio-group v-model="excavationCheck" row>
            <v-radio key="Yes" :label="`Yes`" value="Yes"></v-radio>
            <v-radio key="No" :label="`No`" value="No"></v-radio>
          </v-radio-group>
          <v-row class="subtitle-1">
            Does the work type and area of the excavation remain the same, as
            provided on the original quest?
          </v-row>
          <v-radio-group v-model="workTypeCheck" row>
            <v-radio key="Yes" :label="`Yes`" value="Yes"></v-radio>
            <v-radio key="No" :label="`No`" value="No"></v-radio>
          </v-radio-group>
          <v-card-actions class="mt-6">
            <v-row justify="space-between">
              <v-btn
                outlined
                color="primary"
                class="text-none"
                @click="closeDialog"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                class="text-none"
                :disabled="!excavationCheck || !workTypeCheck"
                @click="alertDialog = true"
              >
                {{ $t("ok") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" width="600">
      <v-card v-if="excavationCheck == 'Yes' && workTypeCheck == 'Yes'">
        <v-card-title class="h5 pl-3"> Update Ticket </v-card-title>
        <v-divider class="mb-6"> </v-divider>
        <v-card-text>
          <v-row>
            Please select from the dropdown below the reason you need to Update
            this ticket for excavation work that will take place in the
            14-calendar day life of the locate request
          </v-row>
          <v-row class="mt-6">
            <v-select
              v-model="selectedExcavation"
              :items="excavationList"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              dense
              required
            >
            </v-select>
          </v-row>
          <v-row>
            <v-divider class="mb-6"> </v-divider>
          </v-row>
          <v-row justify="space-between">
            <v-btn
              outlined
              color="primary"
              @click="closeAlertDialog"
              class="text-none"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!selectedExcavation"
              class="text-none"
              @click="updateTicket"
            >
              {{ $t("ok") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="excavationCheck == 'Yes' && workTypeCheck == 'No'"
        class="text-none"
      >
        <v-card-title class="h5 pl-3"> Alert! </v-card-title>
        <v-divider class="mb-6"> </v-divider>
        <v-card-text>
          <v-row>
            New Locate Request must be submitted because the work type and/or
            excavation area has changed.
          </v-row>
          <v-row>
            <v-divider class="my-6"> </v-divider>
          </v-row>
          <v-row justify="space-between">
            <v-btn
              outlined
              color="primary"
              @click="alertDialog = false"
              class="text-none"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="commentTicket"
              :disabled="!submitButtonClicked"
              class="text-none"
            >
              {{ $t("createANewLocateRequestNow") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="
          excavationCheck == 'No' &&
          (workTypeCheck == 'No' || workTypeCheck == 'Yes')
        "
      >
        <v-card-title class="h5 pl-3"> Alert! </v-card-title>
        <v-divider class="mb-6"> </v-divider>
        <v-card-text>
          <v-row>
            The Locate Request will not be Updated because no excavation is
            going to occur within the 14 day life of a Locate Request and/or the
            work type/area of excavation has changed. If needed, please submit a
            New Locate Request at least 2 workdays before but not more than 14
            days prior to when excavation will take place.
          </v-row>
          <v-row>
            <v-divider class="my-6"> </v-divider>
          </v-row>
          <v-row justify="end">
            <v-btn
              color="primary"
              @click="commentTicket"
              :disabled="!submitButtonClicked"
              class="text-none"
            >
              {{ $t("ok") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    excavationCheck: "",
    workTypeCheck: "",
    alertDialog: false,
    excavationList: [
      "Continued Excavation - extent of project more than 21 days",
      "Continued Excavation - project taking longer than planned",
      "Located Late - need more time for excavation",
    ],
    selectedExcavation: "",
    submitButtonClicked: true,
  }),
  methods: {
    updateTicket() {
      let utahRemark = `>>> As per ${this.$store.state.User.user.name} - Excavation will take place during the 21-Day life of this locate request due to: ${this.selectedExcavation} <<<`;
      let utahComments = `>>> Update Confirmation: Yes, By ${this.$store.state.User.user.name}, Continued excavation - ${this.selectedExcavation} <<<`;
      const NewtinRemark = this.ticketData.remark;
      const NewtinComment = this.ticketData.comments;
      this.$emit("updateTicketDetails", "UpdateTicket", {
        NewtinRemark: NewtinRemark,
        NewtinComment: NewtinComment,
        utahRemark: utahRemark.toUpperCase(),
        utahComments: utahComments.toUpperCase(),
        excavationCheck: this.excavationCheck,
        workTypeCheck: this.workTypeCheck,
        selectedExcavation: this.selectedExcavation,
      });
    },
    async createTicket() {
      await this.$store.commit("resetTicketState");
      await this.$store.commit("setIsEdit", "createTicket");
      await this.$store.commit("setTicketData", {
        state: this.ticketData.state,
      });
      this.$router.push("/createTicket").catch();
    },
    async commentTicket() {
      this.submitButtonClicked = false;
      const comment = `>>> Update Confirmation: No, By ${this.$store.state.User.user.name} <<<`;
      let commentTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
        ticketData: { center: this.ticketData.centerData.center },
      };
      let apiData = {
        data: {
          comment: comment.toUpperCase(),
        },
      };
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      commentTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionCommentTicket",
        commentTicketPayload
      );
      if (updateTicketResp.status === "error") {
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else if (this.excavationCheck == "Yes" && this.workTypeCheck == "No") {
        await this.createTicket();
      } else {
        this.$emit(
          "commentTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.alertDialog = false;
      this.closeDialog();
      this.submitButtonClicked = true;
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.resetUpdateModalData();
    },
    closeAlertDialog() {
      this.alertDialog = false;
      this.selectedExcavation = "";
    },
    resetUpdateModalData() {
      this.excavationCheck = "";
      this.workTypeCheck = "";
      this.selectedExcavation = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
